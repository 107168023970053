import DragableIcon from "@rsuite/icons/Dragable";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import DashboardIcon from "@rsuite/icons/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import PeoplesIcon from "@rsuite/icons/Peoples";
import SiteFillIcon from "@rsuite/icons/SiteFill";
import GridIcon from "@rsuite/icons/Grid";
const user = JSON.parse(localStorage.getItem("user"));
console.log(user);
export const nav = user?.data?.name
  ? [
      {
        title: "Executice",
        icon: <PeoplesIcon />,
        menu: [
          {
            title: "Registration Form",
            link: "/executive-registration",
          },
          {
            title: "Work Status",
            link: "/work-status",
          },
          {
            title: "Verified Executive",
            link: "/verified-executive",
          },
          {
            title: "Deactivated Executive",
            link: "/deactivated-executive",
          },
          {
            title: "Executive Discount",
            link: "/executive-discount",
          },
        ],
      },
    ]
  : [
      {
        title: "Dashboard",
        link: "/",
        icon: <DashboardIcon />,
      },
      {
        title: "Marriage",
        icon: <GridIcon />,
        menu: [
          {
            title: "Create Marriage",
            link: "/create-marriage",
          },
          {
            title: "Unverified Marriage",
            link: "/unverified-marriage",
          },
          {
            title: "Verified Marriage",
            link: "/verified-marriage",
          },
          {
            title: "Deactivated Marriage",
            link: "/deactivated-marriage",
          },
          {
            title: "Unpublished Marriage",
            link: "/unpublished-marriage",
          },
        ],
      },
      {
        title: "Phone Book",
        icon: <GridIcon />,
        menu: [
          {
            title: "New Contact",
            link: "/new-contact",
          },
          {
            title: "Unverified Contact",
            link: "/unverified-contact",
          },
          {
            title: "Verified Contact",
            link: "/verified-contact",
          },
          {
            title: "Deactivated Contact",
            link: "/deactivated-contact",
          },
        ],
      },
      {
        title: "News",
        icon: <PeoplesIcon />,
        menu: [
          {
            title: "Create News",
            link: "/create-news",
          },
          {
            title: "Draft News",
            link: "/draft-news",
          },
          {
            title: "Next Event",
            link: "/next-event",
          },
          {
            title: "Publish News",
            link: "/publish-news",
          },
        ],
      },
      {
        title: "Magazine",
        icon: <PeoplesIcon />,
        menu: [
          {
            title: "Add Cover",
            link: "/add-cover",
          },
          {
            title: "Manage Cover",
            link: "/manage-cover",
          },
          {
            title: "New Registration",
            link: "/new-magazine-registration",
          },
          {
            title: "Paid Member",
            link: "/paid-member",
          },
          {
            title: "Deactivated Member",
            link: "/deactivated-member",
          },
        ],
      },
      {
        title: "Executice",
        icon: <PeoplesIcon />,
        menu: [
          {
            title: "Registration Form",
            link: "/executive-registration",
          },
          {
            title: "New Request",
            link: "/executive-request",
          },
          {
            title: "Verified Executive",
            link: "/verified-executive",
          },
          {
            title: "Deactivated Executive",
            link: "/deactivated-executive",
          },
          {
            title: "Executive Discount",
            link: "/executive-discount",
          },
        ],
      },
    ];
