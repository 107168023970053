import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import api from "../lib/api";

const ExecutiveLogin = () => {
  const [executiveCode, setExecutiveCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { executiveLogin } = useAuth();

  const { mutate, isLoading } = useMutation(
    ["executive-login"],
    async (data) => await api.post("/admin/auth/executive-login", data),
    {
      onSuccess: (res) => {
        if (res.data.status === "success") {
          console.log(res.data);
          executiveLogin(res.data);
        } else if (res.data.status === "userError") {
          setErrorMessage(res.data.message);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleSubmit = () => {
    mutate({
      executive_code: executiveCode,
      password: password,
    });
  };

  return (
    <div>
      <div className="loginContainer vw-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="loginDiv w-25 h-50 shadow rounded p-4">
          <div className="text-center text-white">
            <h2>Executive Login</h2>
          </div>
          <div className="form-group py-2">
            <label htmlFor="ExecutiveCode" className="text-white h6">
              Executive Code
            </label>
            <input
              value={executiveCode.toUpperCase()}
              onChange={(e) => setExecutiveCode(e.target.value)}
              name="ExecutiveCode"
              type="text"
              className="form-control"
              placeholder="Enter Executive Code"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="text-white h6">
              Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              type="password"
              className="form-control"
              placeholder="Enter Password"
            />
            <span className="text-danger">{errorMessage}</span>
          </div>
          <div className="form-group">
            {isLoading ? (
              <button className="btn btn-danger w-100 my-4">
                please wait...
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className="btn btn-danger w-100 my-4"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="loginCopyrightSec">
        <p>
          Copyright &copy; 2022. All Rights Reserved. Developed By{" "}
          <a href="https://www.oriolinfotech.com/" target="_blank">
            Oriol Infotech Solutions
          </a>
        </p>
      </div>
    </div>
  );
};

export default ExecutiveLogin;
