import React, { useEffect, useState } from "react";
import { Link, useOutlet } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  Container,
  Header,
  Sidebar,
  Sidenav,
  Content,
  Navbar,
  Nav,
  Dropdown,
} from "rsuite";
import Footer from "rsuite/Footer";
import { nav } from "../data/nav";
import { HiUserCircle } from "react-icons/hi";
import AppContent from "./AppContent";
import { FaBars } from "react-icons/fa";
import api from "../lib/api";
import { useQuery } from "@tanstack/react-query";

const ProtectedLayout = () => {
  const [expand, setExpand] = useState(false);
  const [mobile, setMobile] = useState(false);
  const { user, logout } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();
  const pathname = location.pathname;

  const { data: requestCount, refetch } = useQuery(
    ["request-count"],
    () => api.get(`/admin/executive/executive-request-count`),
    {
      select: (res) => res.data.data,
    }
  );

  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Container>
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
        width={expand ? 260 : mobile ? 0 : 56}
        collapsible
        onMouseOver={() => {
          setExpand(true);
        }}
        onMouseOut={() => {
          setExpand(false);
        }}
      >
        <Sidenav.Header>
          <span>{expand ? "Kush Prakash" : "K"}</span>{" "}
        </Sidenav.Header>
        <Sidenav expanded={expand} appearance="subtle">
          <Sidenav.Body>
            <Nav>
              {nav.map((item, i) => {
                if (item.menu) {
                  return (
                    <Nav.Menu
                      key={i}
                      eventKey={i + 1}
                      trigger="hover"
                      title={item.title}
                      icon={item.icon}
                      placement="rightStart"
                    >
                      {item.menu.map((item2, j) => {
                        return (
                          <Nav.Item key={j}>
                            <Link to={item2.link}>
                              {item2.title}{" "}
                              {item2.title == "New Request" ? (
                                <>({requestCount && requestCount[0]?.count})</>
                              ) : (
                                <></>
                              )}{" "}
                            </Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav.Menu>
                  );
                } else {
                  return (
                    <Nav.Item key={i} icon={item.icon}>
                      <Link to={item.link}> {item.title}</Link>
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>

      <Container>
        <Header>
          <FaBars
            size={30}
            onClick={() => setExpand(true)}
            className="sideToggle"
          />
          <h4>{pathname.split("/")[1].toUpperCase().replaceAll("-", " ")}</h4>
          <Dropdown
            trigger={["hover"]}
            title={<HiUserCircle />}
            placement="bottomEnd"
          >
            <Dropdown.Item>Profile</Dropdown.Item>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown>
        </Header>
        <Content className="p-2">
          <AppContent />
        </Content>
        <div className="copyrightSec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Copyright &copy; 2022. All Rights Reserved. Developed By{" "}
                  <a href="https://www.oriolinfotech.com/" target="_blank">
                    Oriol Infotech Solutions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default React.memo(ProtectedLayout);
